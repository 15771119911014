// ----------------------------------------------------------------------
function path(root, sublink) {
    return `${root}${sublink}`;
}
const ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
    login: '/login',
    resetPassword: '/forget-password',
};
export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    app: path(ROOTS_DASHBOARD, '/app'),
    profileLabo: path(ROOTS_DASHBOARD, '/profile-labo'),
    profileCabinet: path(ROOTS_DASHBOARD, '/profile-cabinet'),
    branches: path(ROOTS_DASHBOARD, '/branches'),
    draftCommand: path(ROOTS_DASHBOARD, '/draft-commandes'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    consult: path(ROOTS_DASHBOARD, '/consult/:id'),
    order: path(ROOTS_DASHBOARD, '/orders/:id'),
    treatment: path(ROOTS_DASHBOARD, '/treatment/:id'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    config: {
        root: path(ROOTS_DASHBOARD, '/config'),
        options: path(ROOTS_DASHBOARD, '/config/options'),
        types: path(ROOTS_DASHBOARD, '/config/types'),
        article: path(ROOTS_DASHBOARD, '/config/article'),
    },
    archive: {
        root: path(ROOTS_DASHBOARD, '/archive'),
        patient: path(ROOTS_DASHBOARD, '/archive/patient_archive'),
        cabinet: path(ROOTS_DASHBOARD, '/archive/cabinet_archive'),
        order: path(ROOTS_DASHBOARD, '/archive/order_archive'),
    },
    laboratoires: path(ROOTS_DASHBOARD, '/laboratoires'),
    patientForm: path(ROOTS_DASHBOARD, '/patient/form/:step/:id?/:treatmentId?'),
    patient: path(ROOTS_DASHBOARD, '/patient'),
    prosthetist: path(ROOTS_DASHBOARD, '/prosthetist'),
    cabinet: path(ROOTS_DASHBOARD, '/cabinet'),
    permission: path(ROOTS_DASHBOARD, '/permission'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    dentist: path(ROOTS_DASHBOARD, '/dentist'),
};
